import { API } from '@totopkg/shared-util-api';
import { IBaseCrudItem } from '@totopkg/shared-util-crud';

import { IPromotion } from '../../type/promotion.type';

export const patchPromotionUpdateApi = (id: IBaseCrudItem['id'], data: IPromotion) => {
  return new Promise<IPromotion>((resolve, reject) => {
    API.patch<IPromotion>(`/account/promoes/${id}`, data)
      .then(response => resolve(response.data))
      .catch(reject);
  });
};
