import { IBaseCrudItem } from '@totopkg/shared-util-crud';

export enum EPromotionStatus {
  NEW = 'NEW',
  APPLYING = 'APPLYING',
  ENDED = 'ENDED',
  HIDDEN = 'HIDDEN'
}

export interface IPromotion {
  id?: IBaseCrudItem['id'];
  journal?: string;
  adjustType?: string;
  description?: string;
  bonusValue?: number;
  turnoverFactor?: number;
  startAt?: string;
  endAt?: string;
  status?: EPromotionStatus;
  voucherCode?: string;
  contentId?: string;
  content?: {
    title?: string;
    description?: string;
    banner?: string;
    squareBanner?: string;
    priorityNumber?: string;
    categoryLabel?: string;
    link?: string;
    webLink?: string;
  };
  display?: boolean;
  tags?: string[];
  waitAdminApprove?: boolean;
  depositTurnover?: number;
  balanceBonus?: string;
}
