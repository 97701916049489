import { API } from '@totopkg/shared-util-api';

import { IPromotion } from '../../type/promotion.type';

export const postPromotionCreateApi = (data: IPromotion) => {
  return new Promise<IPromotion>((resolve, reject) => {
    API.post<IPromotion>('/account/promoes', data)
      .then(response => resolve(response.data))
      .catch(reject);
  });
};
