import { CrudStore } from '@totopkg/shared-util-crud';

import { getPromotionsAdminApi } from '../api/promotion-admin/get-promotions-admin.api';
import { IPromotionAdmin } from '../type/promotion-admin.type';

export class PromotionsAdmin extends CrudStore.BaseCrudData<IPromotionAdmin, IPromotionAdmin> {
  private static _instance: PromotionsAdmin;

  private constructor() {
    super();
  }

  public static instance = () => {
    if (!this._instance) {
      this._instance = new PromotionsAdmin();
    }

    return this._instance;
  };

  protected builder(): void {
    this.configSearch(getPromotionsAdminApi, {
      defaultSearchParams: {
        page: 1,
        size: 15
      }
    });
  }
}
