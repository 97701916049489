import { EPromotionStatus } from './promotion.type';

export interface IPromotionAdmin {
  adjustType?: string;
  autoApply?: boolean;
  balanceBonus?: string;
  baseOnMoney?: string;
  bonusType?: string;
  bonusValue?: number;
  category?: string[];
  ccy?: string;
  contentId?: string;
  createdAt?: string;
  depositTurnover?: number;
  description?: string;
  display?: boolean;
  endAt?: string;
  filterDataType?: string;
  gameCategory?: string;
  id?: string;
  isAdminPromo?: boolean;
  journal?: string;
  lastModifiedAt?: string;
  maxApplyTime?: number;
  startAt?: string;
  status?: EPromotionStatus;
  statusCreated?: string;
  turnoverFactor?: number;
  version?: number;
  waitAdminApprove?: boolean;
}
