import { API } from '@totopkg/shared-util-api';

import { IBonus } from '../../type/bonus.type';

export const postBonusAcceptApi = (id: IBonus['id']) => {
  return new Promise<IBonus>((resolve, reject) => {
    API.post<IBonus>(`/account/bonusRecords/${id}/accept`)
      .then(response => resolve(response.data))
      .catch(reject);
  });
};
