import { IBaseCrudItem } from '@totopkg/shared-util-crud';

import { IPromotion } from './promotion.type';

export interface IPromotionContent {
  id: IBaseCrudItem['id'];
  content?: string;
}

export type TParsedPromotionContent = IPromotion['content'] & {
  // HTML content
  content: string;
};
