import { API } from '@totopkg/shared-util-api';
import { IBaseCrudItem } from '@totopkg/shared-util-crud';

export const deletePromotionContentApi = (id: IBaseCrudItem['id']) => {
  return new Promise<IBaseCrudItem['id']>((resolve, reject) => {
    API.delete<boolean>(`/account/a/promoContents/${id}`)
      .then(response => resolve(response.data ? id : undefined))
      .catch(reject);
  });
};
