import { API } from '@totopkg/shared-util-api';
import { CrudStore } from '@totopkg/shared-util-crud';

import { IPromotionAdmin } from '../../type/promotion-admin.type';

export type TGetPromotionsAdminParams = CrudStore.TSearchParams<IPromotionAdmin>;

export type TGetPromotionsAdminResponse = CrudStore.TSearchResponse<IPromotionAdmin>;

export type TGetPromotionsAdminAPIResponse = CrudStore.TOutdatedSearchResponse<{
  promos: IPromotionAdmin[];
}>;

export const getPromotionsAdminApi = (params?: TGetPromotionsAdminParams) => {
  return new Promise<TGetPromotionsAdminResponse>((resolve, reject) => {
    API.get<TGetPromotionsAdminAPIResponse>('/account/a/promotions', {
      params
    })
      .then(response => resolve({ data: response.data._embedded?.promos }))
      .catch(reject);
  });
};
