import { CrudStore } from '@totopkg/shared-util-crud';

import { deletePromotionContentApi } from '../api/promotion-content/delete-promotion-content.api';
import { getPromotionContentApi } from '../api/promotion-content/get-promotion-content.api';
import { getPromotionContentsApi } from '../api/promotion-content/get-promotion-contents.api';
import { patchPromotionContentUpdateApi } from '../api/promotion-content/patch-promotion-content-update.api';
import { postPromotionContentCreateApi } from '../api/promotion-content/post-promotion-content-create.api';
import { IPromotionContent } from '../type/promotion-content.type';

export class PromotionContent extends CrudStore.BaseCrudData<IPromotionContent, IPromotionContent> {
  private static _instance: PromotionContent;

  private constructor() {
    super();
  }

  public static instance = () => {
    if (!this._instance) {
      this._instance = new PromotionContent();
    }

    return this._instance;
  };

  protected builder(): void {
    this.config({
      search: {
        default: {
          search: getPromotionContentsApi
        }
      },
      fetch: {
        default: id => getPromotionContentApi(id)
      },
      addNew: {
        default: data => postPromotionContentCreateApi(data as IPromotionContent)
      },
      update: {
        default: (id, data) => patchPromotionContentUpdateApi(id, data as IPromotionContent)
      },
      delete: {
        default: id => deletePromotionContentApi(id)
      }
    });
  }
}
