import { API } from '@totopkg/shared-util-api';
import { IBaseCrudItem } from '@totopkg/shared-util-crud';

import { IPromotionContent } from '../../type/promotion-content.type';

export const getPromotionContentApi = (id: IBaseCrudItem['id']) => {
  return new Promise<IPromotionContent>((resolve, reject) => {
    API.get<IPromotionContent>(`/account/promoContents/${id}`)
      .then(response => resolve(response.data))
      .catch(reject);
  });
};
