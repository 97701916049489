import { API } from '@totopkg/shared-util-api';
import { CrudStore } from '@totopkg/shared-util-crud';

import { IPromotionContent } from '../../type/promotion-content.type';

export type TGetPromotionContentsParams = CrudStore.TSearchParams<IPromotionContent> & {
  ids?: string[];
};

export type TGetPromotionContentsResponse = CrudStore.TSearchResponse<IPromotionContent>;

export const getPromotionContentsApi = (params?: TGetPromotionContentsParams) => {
  return new Promise<TGetPromotionContentsResponse>((resolve, reject) => {
    API.get<IPromotionContent[]>('/account/a/promoContents', {
      params
    })
      .then(response => resolve({ data: response.data }))
      .catch(reject);
  });
};
