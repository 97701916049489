import { IBaseCrudItem } from '@totopkg/shared-util-crud';

export enum EVoucherType {
  LUCKY_WHEEL = 'LUCKY_WHEEL',
  BONUS = 'BONUS'
}

export enum EBonusStatus {
  NONE = 'NONE',
  NEW = 'NEW',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  CANCELLED = 'CANCELLED',
  WAIT_ADMIN_APPROVE = 'WAIT_ADMIN_APPROVE',
  ADMIN_REJECTED = 'ADMIN_REJECTED',
  ADMIN_ACCEPTED = 'ADMIN_ACCEPTED'
}

export interface IBonus {
  id?: IBaseCrudItem['id'];
  balanceId?: string;
  promoId?: string;
  bonusAmt?: number;
  turnoverAmt?: number;
  status?: EBonusStatus;
  expireAt?: string;
  username?: string;
  accountId?: string;
  createdAt?: string;
  contentId?: string;
  contentInner?: {
    title?: string;
    description?: string;
    banner?: string;
    priorityNumber?: string;
    categoryLabel?: string;
  };
  tags?: string[];
}
