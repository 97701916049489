import { CrudStore } from '@totopkg/shared-util-crud';

import { getPromotionsApi, IPromotionParams } from '../api/promotion/get-promotions.api';
import { patchPromotionUpdateApi } from '../api/promotion/patch-promotion-update.api';
import { postPromotionCreateApi } from '../api/promotion/post-promotion-create.api';
import { IPromotion } from '../type/promotion.type';

export class Promotions extends CrudStore.BaseCrudData<IPromotion, IPromotionParams> {
  private static _instance: Promotions;

  private constructor() {
    super();
  }

  public static instance = () => {
    if (!this._instance) {
      this._instance = new Promotions();
    }

    return this._instance;
  };

  protected builder(): void {
    this.config({
      search: {
        default: {
          search: getPromotionsApi
        }
      },
      addNew: {
        default: data => postPromotionCreateApi(data as IPromotion)
      },
      update: {
        default: (id, data) => patchPromotionUpdateApi(id, data as IPromotion)
      }
    });
  }
}
