import { API } from '@totopkg/shared-util-api';
import { CrudStore } from '@totopkg/shared-util-crud';

import { IBonus } from '../../type/bonus.type';

export type TGetBonusRecordsParams = CrudStore.TSearchParams<IBonus>;

export type TGetBonusRecordsResponse = CrudStore.TSearchResponse<IBonus>;

export const getBonusRecordsApi = (params?: TGetBonusRecordsParams) => {
  return new Promise<TGetBonusRecordsResponse>((resolve, reject) => {
    API.get<IBonus[]>('/account/bonusRecords/me/v2', {
      params
    })
      .then(response => resolve({ data: response.data }))
      .catch(reject);
  });
};
